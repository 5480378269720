
























































































































































import { Component, Vue } from "vue-property-decorator";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import DialogBox from "@/components/DialogBox.vue";
import GeneralTab from "@/components/orders/general/GeneralTab.vue";
import BillingDeliveryTab from "@/components/orders/billing-delivery/BillingDeliveryTab.vue";
import CustomerTab from "@/components/orders/customer/CustomerTab.vue";
import FactoriesTab from "@/components/orders/factories/FactoriesTab.vue";
import ProductsTab from "@/components/orders/products/ProductsTab.vue";
import OrderPacksTab from "@/components/orders/order-packs/OrderPacksTab.vue";
import AttachmentsTab from "@/components/orders/attachments/AttachmentsTab.vue";
import HistoryTab from "@/components/orders/history/HistoryTab.vue";
import InvoicesTab from "@/components/orders/invoices/InvoicesTab.vue";
import CostsTab from "@/components/orders/costs/CostsTab.vue";
import PaymentsTab from "@/components/orders/payments/PaymentsTab.vue";
import OrderTasks from "@/components/orders/activity-tasks/OrderTasks.vue";
import { formatDate } from "@/utils/formatDate";
import {
  AdminOrdersIdGetRequest,
  AvailableOrderStatusViewModel,
  CurrencyAdminViewModel,
  DeliveryType,
  OrderAdminViewModel,
  OrderAdminViewModelPrimaryBillingAddress,
  OrderCostAdminListViewModel,
  OrderCostAdminListViewModelPaginatedList,
  OrderStatus,
  OrderStatusListViewModel,
  UserAdminListViewModel
} from "@/api-client";
import {
  AdminOrders,
  AdminUsers,
} from "@/network/api";
import { email } from "vee-validate/dist/rules";
import { Message } from 'element-ui';
import endent from 'endent'

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})
@Component({
  name: "EditOrder",
  components: {
    ValidationProvider,
    ValidationObserver,
    DialogBox,
    GeneralTab,
    CustomerTab,
    FactoriesTab,
    BillingDeliveryTab,
    CostsTab,
    ProductsTab,
    PaymentsTab,
    AttachmentsTab,
    OrderTasks,
    OrderPacksTab,
    HistoryTab,
    InvoicesTab
  },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  dirtyTimer: any = null;
  activeTab: any = "general";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  loading = false;
  currencies: Array<CurrencyAdminViewModel> = [];
  order: OrderAdminViewModel = {
    id: "",
    countryId: "",
    isGift: true,
    giftMessage: "",
    orderStatusId: OrderStatus.NewOrder,
    deliveryTypeId: DeliveryType.Standard,
    currency: {
      id: "",
      name: "",
      code: "",
      symbol: "",
      pricingMultiplier: 0,
      decimalPlaces: 0,
    },
    orderManagerAspNetUserId: '',
    orderManagerAspNetUserDisplayName: "",
    salesManagerAspNetUserId: '',
    salesManagerAspNetUserDisplayName: "",
    requiresCustomerApproval: true,
    convertedOrderBalance: 0,
    orderDocuments: [],
    factories: [],
    orderPlacementDate: "",
    expectedDeliveryDate: "",
    exWorksDate: "",
    groupedUK: false,
    groupedUSA: false,
    approvalDate: "",
    referenceNumber: "",
    convertedOrderTotal: 0,
    enquiryId: '',
    totalQuantity: 0,
    productionNotes: '',
    readyForUploadDate: '',
    dispatchNotes: '',
    dispatchedDate: '',
    orderPackIdAwaitingApproval: '',
    showOrderPackApprovalLink: false,
    showAssetResupplyLink: false,
    showOutstandingPaymentLink: false,
    courier: {
      id: "",
      name: "",
      trackingUrlFormat: "",
    },
    courierReference: "",
    courierLink: "",
    primaryBillingAddress: {
      id: "",
      companyName: "",
      addressLine1: "",
      addressLine2: "",
      area: "",
      country: "",
      postalCode: "",
      additionalNotes: "",
      ponumber: "",
    },
    primaryDeliveryAddress: {
      id: "",
      companyName: "",
      addressLine1: "",
      addressLine2: "",
      area: "",
      country: "",
      postalCode: "",
      additionalNotes: "",
      ponumber: "",
    },
    additionalAddresses: [],
    primaryCustomerContact: {
      id: "",
      name: "",
      email: "",
      phoneNumber: "",
    },
    additionalCustomerContacts: [],
    orderItems: [],
    accessKey: '',
    allowedPaymentProviders: [],
    paymentInternalReason: '',
    paymentExternalReason: '',
    disableQuoteReminder: true,
    customerContacts: [],
    assetsRedrawRequested: false,
    disableAutoSendFactoryEmails: false,
    specialTerms: null
  };
  orderClone: OrderAdminViewModel = {
    id: "",
    countryId: "",
    isGift: true,
    giftMessage: "",
    orderStatusId: OrderStatus.NewOrder,
    deliveryTypeId: DeliveryType.Standard,
    currency: {
      id: "",
      name: "",
      code: "",
      symbol: "",
      pricingMultiplier: 0,
      decimalPlaces: 0,
    },
    orderManagerAspNetUserId: '',
    orderManagerAspNetUserDisplayName: "",
    salesManagerAspNetUserId: '',
    salesManagerAspNetUserDisplayName: "",
    requiresCustomerApproval: true,
    convertedOrderBalance: 0,
    orderDocuments: [],
    factories: [],
    orderPlacementDate: "",
    expectedDeliveryDate: "",
    exWorksDate: "",
    groupedUK: false,
    groupedUSA: false,
    noFail: undefined,
    approvalDate: "",
    referenceNumber: "",
    convertedOrderTotal: 0,
    orderPackNotes: "",
    specialTerms: null,
    totalQuantity: 0,
    productionNotes: '',
    readyForUploadDate: '',
    dispatchNotes: '',
    dispatchedDate: '',
    orderPackIdAwaitingApproval: '',
    showOrderPackApprovalLink: false,
    showAssetResupplyLink: false,
    showOutstandingPaymentLink: false,
    lastModifiedDate: '',
    lostReason: '',
    paymentInternalReason: '',
    paymentExternalReason: '',
    cancellationReason: '',
    courier: {
      id: "",
      name: "",
      trackingUrlFormat: "",
    },
    courierReference: "",
    courierLink: "",
    primaryBillingAddress: {
      id: "",
      companyName: "",
      addressLine1: "",
      addressLine2: "",
      area: "",
      country: "",
      postalCode: "",
      additionalNotes: "",
      ponumber: "",
    },
    primaryDeliveryAddress: {
      id: "",
      companyName: "",
      addressLine1: "",
      addressLine2: "",
      area: "",
      country: "",
      postalCode: "",
      additionalNotes: "",
      ponumber: "",
    },
    additionalAddresses: [],
    primaryCustomerContact: {
      id: "",
      name: "",
      email: "",
      phoneNumber: "",
    },
    additionalCustomerContacts: [],
    orderItems: [],
    quoteMessage: '',
    allowedPaymentProviders: [],
    accessKey: '',
    disableQuoteReminder: true,
    customerContacts: [],
    assetsRedrawRequested: false,
    disableAutoSendFactoryEmails: false
  };
  orderCostPages: OrderCostAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  orderCosts: Array<OrderCostAdminListViewModel> = [];
  clearDirty: any = null;
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };
  messageInstance: any = Message
  orderStatuses: Array<OrderStatusListViewModel> = [];
  reloadOrderPack = false
  graphData:string = ''
  currentStatus: AvailableOrderStatusViewModel | null = null
  nextStatus: AvailableOrderStatusViewModel | null = null
  previousStatus: AvailableOrderStatusViewModel | null = null
  miscStatuses: Array<AvailableOrderStatusViewModel> | null = null
  accordianValue = ['', 'graph']
  clearReasons = false
  convertedOrderBalance = 0
  fromPath = ''
  voucherId: string | null = null
  usersList: Array<UserAdminListViewModel> = [];
  newUserAccountId: string | null = null;

  get diagram() {
    if(this.graphData) {
      return endent`${this.graphData}`
    }
    return ''
  }

  created() {
    this.loadInitialData().then(() => {
      this.clearDirty = setTimeout(() => {
        this.clearDirtyClasses();
      }, 500);
    });

    if (this.$route.query.tab) {
      this.activeTab = this.$route.query.tab;
    } else {
      this.activeTab = "general";
    }

    this.$watch("$route", () => {
      if (this.$route.query.tab) {
        this.activeTab = this.$route.query.tab;
      } else {
        this.activeTab = "general";
      }
    });

    // this.$watch("accordianValue", () => {
    //   if (this.accordianValue = 'graph') {
    //     this.loadGraph();
    //   } 
    // });

    if((this.orderClone.deliveryTypeId ==='Express') && (this.orderClone.noFail == undefined)) {
      this.orderClone.noFail = true
    }

    this.$watch("orderClone.deliveryTypeId", () => {
      if ((this.orderClone.deliveryTypeId ==='Express') && (this.orderClone.noFail == undefined)) {
        this.orderClone.noFail = true
      } else if(this.orderClone.deliveryTypeId === 'Standard') {
        this.orderClone.noFail = undefined
      }
    });
    

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    extend("email", {
      ...email,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }


  mounted() {
    this.$refs.observer;

    this.$watch("orderClone.orderManagerAspNetUserId", (prevVal, newVal) => {
      if(newVal) {
        this.handleSubmit(null, true)
      }
    })

    this.$watch("orderClone.salesManagerAspNetUserId", (prevVal, newVal) => {
      if(newVal) {
        this.handleSubmit(null, true)
      }
    })
  }

  get readOnlyCosts() {
    if(this.orderClone.id) {
      let statuses = ['Cancelled', 'OnHold', 'Lost', 'AwaitingPayment'] // previously had 'Cart', 'CartValidated' as well
      if(statuses.includes(this.order.orderStatusId)) {
        return true
      }
      return false
    }
    return true
  }

  get readOnly() {
    if(this.orderClone.id) {
      let statuses = ['Cancelled', 'OnHold', 'Lost'] // previously had 'Cart', 'CartValidated', 'PreparingQuote', 'QuoteProvided', 'AmendQuote' as well
      if(statuses.includes(this.order.orderStatusId)) {
        return true
      }
      return false
    }
    return true
  }

  handleDate(date:any) {
    return formatDate(date)
  }

  async attemptPaymentEmail(response:any) {
    if(this.previousStatus) {
      await this.submitForm(this.currentStatus?.id == OrderStatus.BankTransferPending ? this.currentStatus.id : this.previousStatus.id)
      .then(() => {
        this.loading = true
          AdminOrders.adminOrdersSendOutstandingPaymentEmailOrderIdPost(this.order.id, response.internal, response.external)
          .then((res) => {
            if (res.data.succeeded) {
              this.$message.success('Email sent successfully.');
              this.loadInitialData()
              this.clearReasons = true
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            error.response.data.errors.map((e: any) => {
              this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
              return e.friendlyMessage;
            });
          });
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          this.$message({showClose: true, type: 'error', duration: 0, message: "There was an error processing your request."});
          console.log(error);
        });
    } else {
      this.$message({showClose: true, type: 'error', duration: 0, message: "Unable to move to a valid status to send a payment request."});
    }
  }

  handleUpdateAddress(address: OrderAdminViewModelPrimaryBillingAddress | null) {
    this.orderClone.primaryDeliveryAddress = address;
  }

  handleSubmit (status:OrderStatus | null = null, saveManager = false, scroll = false) {
    this.messageInstance.closeAll()
    let errorMessages = [] as Array<string>
    
    if ((status === 'Dispatched') && !this.orderClone.exWorksApproved) {
      this.$message({showClose: true, type: 'error', duration: 0, message: "Ex Works approval is required before dispatching."});
      this.orderClone.orderManagerAspNetUserId = this.order.orderManagerAspNetUserId
      this.orderClone.salesManagerAspNetUserId = this.order.salesManagerAspNetUserId
      this.orderClone.orderStatusId = this.order.orderStatusId
    } else {
      this.$refs.observer.validateWithInfo().then(({ isValid, errors }) => {
        if(isValid) {
          this.submitForm(status, scroll)
        } else {
          if(saveManager) {
            this.orderClone.orderManagerAspNetUserId = this.order.orderManagerAspNetUserId
            this.orderClone.salesManagerAspNetUserId = this.order.salesManagerAspNetUserId
          }
          if(status) {
            this.orderClone.orderStatusId = this.order.orderStatusId
          }
          for(let error in errors) {
            if(errors[error].length > 0) {
              errorMessages.push(error)
            }
          }
          let finalMessage = `The following fields are required:<br>`
          errorMessages.forEach((message, index:any) => {
            if (index < 5) {
              finalMessage += `<br>- ${message}`
            }
          })
          if(errorMessages.length > 5) {
            let remaining = errorMessages.length - 5
            finalMessage += `<br><br>and ${remaining} others...`
          }
          this.$message({showClose: true, type: 'error', duration: 0, message: finalMessage, dangerouslyUseHTMLString: true});
        }
      });
    }
  }

  handleTabClick(tab: any) {
    tab.name ? (this.activeTab = tab.name) : (this.activeTab = tab);
    this.$router.replace({
      name: "EditOrder",
      params: { id: this.orderClone.id },
      query: { tab: this.activeTab },
    });
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer);
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.dialogMessage =
      "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
    this.confirmText = "Yes";
    if (isDirty.length > 0) {
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if(['OrderEnquirySearch', 'Orders','TaskOverview'].includes(this.fromPath)) {
        this.$router.back()
      } else {
        this.$router.push({ name: "Orders"});
      }
    } else {
      this.submitForm();
    }
  }

  async loadGraph() {
    if(this.accordianValue.length > 1) {
    await AdminOrders.adminOrdersGetStatusGraphIdGet(this.orderClone.id)
    .then((res) => {
        if (res.data.succeeded) {
          this.graphData = res.data
            .resultData as string;
        }
      })
      .catch((error) => {
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
    }
  }

  async loadUsers() {
    await AdminUsers.adminUsersGetUsersForOrderGet(1, 9999999)
    .then((res) => {
        if (res.data.succeeded) {
          this.usersList = res.data.resultData?.items as Array<UserAdminListViewModel>;
        }
      })
      .catch((error) => {
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
  }

  async submitForm(status:OrderStatus | null = null, scroll = false) {
    if(typeof status === 'string') {
      this.orderClone.orderStatusId = status
    }
    let newOrder = {
      allowedPaymentProviders: this.orderClone.allowedPaymentProviders,
      countryId: this.orderClone.countryId,
      factories: [... this.orderClone.factories],
      isGift: this.orderClone.isGift,
      giftMessage: this.orderClone.giftMessage,
      orderStatusId: this.orderClone.orderStatusId,
      deliveryTypeId: this.orderClone.deliveryTypeId,
      currencyId: this.orderClone.currency.id,
      requiresCustomerApproval: this.orderClone.requiresCustomerApproval,
      quoteExpiryDate: this.orderClone.quoteExpiryDate,
      updatedDeliveryDate: this.orderClone.updatedDeliveryDate,
      expectedDeliveryDate: this.orderClone.expectedDeliveryDate,
      exWorksDate: this.orderClone.exWorksDate,
      referenceNumber: this.orderClone.referenceNumber,
      orderManagerAspNetUserId: this.orderClone.orderManagerAspNetUserId,
      salesManagerAspNetUserId: this.orderClone.salesManagerAspNetUserId,
      courierId: this.orderClone.courier?.id,
      courierReference: this.orderClone.courierReference,
      primaryBillingAddress: this.orderClone.primaryBillingAddress ? {...this.orderClone.primaryBillingAddress} : null,
      primaryDeliveryAddress: this.orderClone.primaryDeliveryAddress ? {...this.orderClone.primaryDeliveryAddress} : null,
      additionalAddresses: [...this.orderClone.additionalAddresses],
      primaryCustomerContact: this.orderClone.primaryCustomerContact ? {...this.orderClone.primaryCustomerContact} : null,
      additionalCustomerContacts: [...this.orderClone.additionalCustomerContacts],
      noFail: this.orderClone.noFail,
      specialTerms: this.orderClone.specialTerms,
      orderPackNotes: this.orderClone.orderPackNotes,
      exWorksConfirmedByFactoryDate: this.orderClone.exWorksConfirmedByFactoryDate,
      exWorksNotes: this.orderClone.exWorksNotes,
      exWorksApproved: this.orderClone.exWorksApproved,
      productionNotes: this.orderClone.productionNotes,
      readyForUploadDate: this.orderClone.readyForUploadDate,
      dispatchNotes: this.orderClone.dispatchNotes,
      dispatchedDate: this.orderClone.dispatchedDate,
      lostReason: this.orderClone.lostReason,
      groupedUK: this.orderClone.groupedUK,
      groupedUSA: this.orderClone.groupedUSA,
      cancellationReason: this.orderClone.cancellationReason,
      orderItems: [...this.orderClone.orderItems.map((item:any) => {
        return ({
          id: item.id,
          quantities: [...item.quantities],
          layers: [...item.customizedProduct.layers.map((layer:any) => {
            return (
              {
                layerId: layer.id,
                notes: layer.notes,
              }
            )
          })
            ,
          ],
          orderPackNotes: item.orderPackNotes
        })
      })],
      orderDocuments: [...this.orderClone.orderDocuments.map((doc:any) => {
        return ({
          id: doc.id,
          documentUrl: doc.documentUrl,
        })
      })
      ],
      disableQuoteReminder: this.orderClone.disableQuoteReminder,
      voucherId: this.voucherId,
      aspNetUserId: this.newUserAccountId ? this.newUserAccountId : this.orderClone.aspNetUserId,
      disableAutoSendFactoryEmails: this.orderClone.disableAutoSendFactoryEmails,
    } as AdminOrdersIdGetRequest;
    console.log("order before save", newOrder);

    if (this.orderClone.id) {
      this.loading = true;
      console.log("before save", this.order);
      await AdminOrders.adminOrdersIdPut(this.orderClone.id, newOrder)
        .then((res) => {
          if (res.data.succeeded) {
            console.log("success?", res);
            this.$message.success("Saved!");
            this.loadInitialData().then(() => {
              this.clearDirty = setTimeout(() => {
              this.clearDirtyClasses(), 0})
              this.reloadOrderPack = true
            });
            if(scroll) {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }
          } else {
            this.orderClone.orderStatusId = this.order.orderStatusId
            this.orderClone.orderManagerAspNetUserId = this.order.orderManagerAspNetUserId
            this.orderClone.salesManagerAspNetUserId = this.order.salesManagerAspNetUserId
          }
        })
        .catch((error) => {
          this.orderClone.orderStatusId = this.order.orderStatusId
          this.orderClone.orderManagerAspNetUserId = this.order.orderManagerAspNetUserId
          this.orderClone.salesManagerAspNetUserId = this.order.salesManagerAspNetUserId
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });
      // await this.generateOrderPack()
    }
    this.loading = false;
    this.dialogVisible = false;
  }

  async getOrderBalance(balance:number | null) {
    if((balance == null) || ((balance >= 0) && (balance !== this.convertedOrderBalance))) {
      await AdminOrders.adminOrdersGetConvertedOrderBalanceIdGet(this.order.id)
      .then((res) => {
        if (res.data.succeeded) {
          this.convertedOrderBalance = res.data.resultData as number;
        }
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
    }
  }

  async loadInitialData() {
    if (this.id) {
      this.loading = true;
      await AdminOrders.adminOrdersIdGet(this.id)
        .then((res) => {
          if (res.data.succeeded) {
            this.order = res.data.resultData as OrderAdminViewModel;
            this.orderClone = { ...this.order };
          }
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });

      await AdminOrders.adminOrdersGetAvailableOrderStatusesForOrderIdGet(this.orderClone.id)
      .then((res) => {
          if (res.data.succeeded) {
            let statuses = res.data.resultData
            this.miscStatuses = null
            this.currentStatus = this.nextStatus = this.previousStatus = null;
            if(statuses?.length) {
              for(let i = 0; i < statuses.length; i++) {
                if(statuses[i].isCurrentStatus) {
                  this.currentStatus = statuses[i];
                  continue
                }

                if(statuses[i].isNextStatus) {
                  this.nextStatus = statuses[i];
                  continue
                }

                if(statuses[i].isPreviousStatus) {
                  this.previousStatus = statuses[i];
                  continue
                }

                if(!statuses[i].isCurrentStatus && !statuses[i].isNextStatus && !statuses[i].isPreviousStatus) {
                  if(!this.miscStatuses) {
                    this.miscStatuses = []
                  }
                  this.miscStatuses.push(statuses[i]);
                  continue
                }
              }
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });

        await this.getOrderBalance(null)

        await this.loadGraph();

        await this.loadUsers();
    }

    this.loading = false;
  }

  beforeUnmount() {
    this.messageInstance.closeAll()
    clearTimeout(this.clearDirty);
  }
}
